import React from "react";

import stepsImg from "../assets/img/features-2.png";

const Steps = (props) => {
  return (
    <div className='row feture-tabs' data-aos='fade-up'>
      <div className='col-lg-6'>
        <h3>How we work</h3>

        <ul className='nav nav-pills mb-3'>
          <li>
            <a className='nav-link active' data-bs-toggle='pill' href='#tab1'>
              Step 01
            </a>
          </li>
          <li>
            <a className='nav-link' data-bs-toggle='pill' href='#tab2'>
              Step 02
            </a>
          </li>
          <li>
            <a className='nav-link' data-bs-toggle='pill' href='#tab3'>
              Step 03
            </a>
          </li>
          <li>
            <a className='nav-link' data-bs-toggle='pill' href='#tab4'>
              Step 04
            </a>
          </li>

          <li>
            <a className='nav-link' data-bs-toggle='pill' href='#tab5'>
              Step 05
            </a>
          </li>

          <li>
            <a className='nav-link' data-bs-toggle='pill' href='#tab6'>
              REPEAT
            </a>
          </li>
        </ul>

        <div className='tab-content'>
          <div className='tab-pane fade show active' id='tab1'>
            <p>
              We start with a meeting where we discuss your needs for IT
              solutions for your business. We will ask questions which will help
              us to get full image of your idea.
            </p>
            <p>
              It is crucial for us to understand your needs in order to plan an
              individual path of action.
            </p>
          </div>

          <div className='tab-pane fade show' id='tab2'>
            <p>
              During this phase our team will carefully study and analyse all
              the requirements we gathered during Step 01. We will determine{" "}
              <b>WHAT</b> the system should do and model initial approaches
              which will allow us to implement the system with minimum risks
              possible.
            </p>
            <p>
              Once we get your approval on those specifications we will be able
              to move onto the next step.
            </p>
          </div>

          <div className='tab-pane fade show' id='tab3'>
            <p>
              This is the stage where we design the system accordign to the
              requirements specifications. This is mainly about <b>HOW</b> the
              system will meet the required goals.
            </p>
            <p>
              If project requires user facing interface, our UI/UX Designers
              will start sketching prototypes now.
            </p>
          </div>

          <div className='tab-pane fade show' id='tab4'>
            <p>
              This step takes most of the time during project development. It
              consist of several sub-steps repeated in cycles over the time.
              Basically we take chunks of prioritised system functionalities and
              do the following:
            </p>
            <div className='d-flex align-items-center mb-2'>
              <i className='bi bi-check2'></i>
              <h4>Code</h4>
            </div>
            <div className='d-flex align-items-center mb-2'>
              <i className='bi bi-check2'></i>
              <h4>Test</h4>
            </div>
            <div className='d-flex align-items-center mb-2'>
              <i className='bi bi-check2'></i>
              <h4>Document</h4>
            </div>
            <div className='d-flex align-items-center mb-2'>
              <i className='bi bi-check2'></i>
              <h4>Implement</h4>
            </div>
            <p>
              After each full cycle completes you will be able to see the actual
              progress of our work!
            </p>
            <p>
              Thanks to the Agile apprach we are able to adjust to changing
              requirements, make corrections and enchancements.
            </p>
          </div>

          <div className='tab-pane fade show' id='tab5'>
            <p>
              At this stage we have finished coding. The product is ready to go
              live. You don't have to worry about the infrastructure, scaling or
              monitoring of your product. We have it all set it up for you.
            </p>
            <p>
              Shall you require any post-implementation support we are always
              ready to give you a helping hand!
            </p>
          </div>

          <div className='tab-pane fade show' id='tab6'>
            <p>That's it! It wasn't that scary at all, right?</p>
            <p>
              Now that your product is growing in revenue and you don't have to
              worry about anything, you... want more! So what you do? You reach
              for another idea in your head and you call us. Now that we are
              trusted partners you cannot imagine creating new product with
              anyone else.
            </p>
          </div>
        </div>
      </div>

      <div className='col-lg-6'>
        <img src={stepsImg} className='img-fluid' alt='' />
      </div>
    </div>
  );
};
export default Steps;
