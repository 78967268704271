import valueImg1 from "../assets/img/values-1.png";
import valueImg2 from "../assets/img/values-2.png";
import valueImg3 from "../assets/img/values-3.png";

export const navElements = [
  { name: "Home", path: "#home" },
  { name: "About", path: "#about" },
  { name: "Services", path: "#services" },
  { name: "Projects", path: "#projects" },
  { name: "Contact", path: "#contact" },
];

export const companyName = "Master Branch";
export const contactEmail = "contact@masterbranch.it";

export const guaranteeElements = [
  "Fully functional product delivered on time & on budget",
  "Transparency on every stage of the process",
  "End product fitted to your highest expectations",
  "Use only best practices and reliable technologies",
  "Post-implementation support",
  "You own the code-base",
];

export const valueElements = [
  {
    title: "Transparency",
    text: " It doesn’t matter whether you are an individual, a small company or a corporation - we will make sure you are always involved with the development process.",
    imgSrc: valueImg1,
  },
  {
    title: "Going extra mile",
    text: "We will go the extra mile to meet the customers expectations, we do not expect defined requirements from the get-go or technical knowledge. On the contrary, our goal is to understand your needs and provide solutions that will bring the value you expect.",
    imgSrc: valueImg2,
  },
  {
    title: "One stop IT solution",
    text: " Our proposition comes with the Operation aspect of IT. We will plan, implement, secure and support your Cloud infrastructure. We have expertise in designing, configuration, administration and hardening of various IT services.",
    imgSrc: valueImg3,
  },
];

export const serviceElements = [
  "Web & mobile applications",
  "Desktop applications",
  "System maintenance",
  "System Administration",
  "Project management",
  "Chatbots",
  "IoT Systems",
  "UX/UI Design",
  "Big Data",
  "Monitoring",
  "Disaster Recovery Solutions",
  "Cloud Infrastructure",
];

export const portfolioItems = [
  {
    title: "Biotechnology platform",
    subtitle:
      "Developing internal platform for scientists to facilitate their workflow.",
  },
  {
    title: "Onboarding and offboarding automation",
    subtitle:
      "Microsoft based solution to automate onboarding and offboarding the whole process across all departmens.",
  },
  {
    title: "Cloud migration",
    subtitle:
      "Migration of mission-critical services and development environment to Azure.",
  },
  {
    title: "Proxy services",
    subtitle:
      "Platform & infrastructure for proxying and orchestrating online traffic.",
  },
  {
    title: "Containerization of development",
    subtitle:
      "Introduction and automation of container based development environment for whole delivery structure.",
  },
  {
    title: "Security audit & hardening",
    subtitle:
      "Security audit across whole infrastructure and services, proposed improvements were implemented and services have been hardened.",
  },
  {
    title: "Recruitment platform & chatbot",
    subtitle:
      "Recruitment platform whic used chatbot to gather user information.",
  },
  {
    title: "Security & Services Monitoring",
    subtitle:
      "Implementation of monitoring system based on automated intrusion detection. Introduction of monitoring of IT services.",
  },
  {
    title: "Crypto wallet",
    subtitle:
      "Platform integrated with own blockchain for managing and transfering crypto.",
  },
  {
    title: "Crypto games",
    subtitle:
      "Platform integrated with own blockchain oferring various casino games using crypto.",
  },
  {
    title: "Transparent crypto exchange",
    subtitle: "Transparent crypto exchange with core trading functionalities.",
  },
  {
    title: "Disaster Recovery",
    subtitle:
      "Design, build and support of whole disaster recovery process and services (hybrid environment).",
  },
  {
    title: "Centralization of logs",
    subtitle:
      "Implementation of centralized log services aswell as proactive trend detection",
  },
  {
    title: "IoT EV charger",
    subtitle:
      "Cross-platform mobile app and IoT module to manage proprietary EV chargers.",
  },
  {
    title: "Smart Lighting System",
    subtitle:
      "Cross-platform mobile app and IoT module for Smart Lighting System.",
  },
  {
    title: "E-commerce platform",
    subtitle: "Development of e-commerce store for online retail.",
  },
  {
    title: "Crawler service",
    subtitle:
      "Crawling websites with multi-milion data points and generating reports.",
  },
  {
    title: "Hotel booking system",
    subtitle: "Development of hotel reservation engine.",
  },
  {
    title: "Security platform",
    subtitle:
      "Web isolation to protect users against threats by executing all code and content in a remote containerized envionment.",
  },
  {
    title: "Payment gateway integrations",
    subtitle: "Development of a scheduled payment integration.",
  },
  {
    title: "Sub-account system for the UN",
    subtitle: "Development of the backend system for the UN.",
  },
  {
    title: "Mobile Virtual Network Operator",
    subtitle: "Development of wrappers for several network operators.",
  },
  {
    title: "Direct Inward Dialing telecommunications",
    subtitle: "Development of an interface of several asterisk services.",
  },
  {
    title: "IoT Sensor",
    subtitle:
      "Development of an IoT device to monitor environmental conditions.",
  },
  {
    title: "Yacht charter management system",
    subtitle:
      "Development of yacht charter management system, including reservation engine.",
  },
];
