import React from "react";

const PortfolioItem = (props) => {
  const { imgSrc, title, subtitle } = props;
  return (
    <div class='col-lg-4 col-md-6 portfolio-item'>
      <div class='portfolio-wrap'>
        {/* <img src={imgSrc} class='img-fluid' alt='' /> */}
        <div class='portfolio-info'>
          <h4>{title}</h4>
          <p>{subtitle}</p>
        </div>
      </div>
    </div>
  );
};

export default PortfolioItem;
