import React from "react";

import LinkNavBtm from "../components/links/LinkNavBtm";

import { navElements } from "../consts/common";

import Logo from "../components/Logo";

import { contactEmail, companyName } from "../consts/common";

const Footer = (props) => {
  return (
    <footer id='footer' className='footer'>
      <div className='footer-top'>
        <div className='container'>
          <div className='row gy-4'>
            <div className='col-lg-7 col-md-12 footer-info'>
              <Logo />
              <p>
                We are committed to implementing comprehensive IT solutions to
                meet your business goals and needs.
              </p>
              <br />
              <p>
                Master Branch is a part of{" "}
                <a href='https://cohrman.pl'>Cohrman Group</a>.
              </p>
            </div>

            <div className='col-lg-2 col-6 footer-links'>
              <h4>Useful Links</h4>
              <ul>
                {navElements.map((el, idx) => (
                  <LinkNavBtm key={idx} navEl={el} />
                ))}
              </ul>
            </div>

            <div className='col-lg-3 col-md-12 footer-contact text-center text-md-start'>
              <h4>Contact Us</h4>
              <p>
                Varso Place <br />
                Chmielna 73, 00-801 Warsaw
                <br />
                Poland <br />
                <br />
                <strong>Email:</strong>{" "}
                <a href={`mailto:${contactEmail}?subject=Contact enquiry`}>
                  {contactEmail}
                </a>
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='copyright'>
          &copy; Copyright{" "}
          <strong>
            <span>{companyName}</span>
          </strong>
          . All Rights Reserved
        </div>
      </div>
    </footer>
  );
};

export default Footer;
