import React from "react";

import guaranteeImg from "../assets/img/features.png";
import FeatureBox from "../components/FeatureBox";
import { guaranteeElements } from "../consts/common";

const Guarantee = (props) => {
  return (
    <React.Fragment>
      <header className='section-header'>
        <p>What we guarantee</p>
      </header>

      <div className='row'>
        <div className='col-lg-6'>
          <img src={guaranteeImg} className='img-fluid' alt='' />
        </div>

        <div className='col-lg-6 mt-5 mt-lg-0 d-flex'>
          <div className='row align-self-center gy-4'>
            {guaranteeElements.map((text, idx) => (
              <FeatureBox key={idx} text={text} />
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Guarantee;
