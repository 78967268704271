import React from "react";

import About from "./About";
import Guarantee from "./Guarantee";
import Values from "./Values";
import Steps from "./Steps";
import Services from "./Services";
import Contact from "./Contact";
import Portfolio from "./Portfolio";

const MainSection = (props) => {
  return (
    <main id='main'>
      <About />
      <section id='features' className='features'>
        <div className='container' data-aos='fade-up'>
          <Guarantee />
          <Values />
          <Steps />
        </div>
      </section>
      <Services />
      <Portfolio />
      <Contact />
    </main>
  );
};

export default MainSection;
