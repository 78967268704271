import React from "react";

import { contactEmail } from "../consts/common";

const MailtoBtn = (props) => {
  return (
    <div data-aos='fade-up' data-aos-delay='600'>
      <div className='text-center'>
        <a
          href={`mailto:${contactEmail}?subject=Contact enquiry`}
          className='btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center'
        >
          <span>Contact us</span>
          <i className='bi bi-arrow-right'></i>
        </a>
      </div>
    </div>
  );
};

export default MailtoBtn;
