import React from "react";

const ValueBox = (props) => {
  const { imgSrc, title, text } = props;

  return (
    <div className='col-lg-4 mt-4 mt-lg-0'>
      <div className='box' data-aos='fade-up' data-aos-delay='400'>
        <img src={imgSrc} className='img-fluid' alt='' />
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default ValueBox;
