import React from "react";
import _ from "lodash";

const LinkNavBtm = (props) => {
  const { navEl } = props;

  return (
    <li>
      <i className='bi bi-chevron-right'></i>

      <a href={navEl.path}>{_.capitalize(navEl.name)}</a>
    </li>
  );
};

export default LinkNavBtm;
