import React from "react";

import heroImg from "../assets/img/hero-img.png";
import ContactBtn from "../components/ContactBtn";

const Hero = (props) => {
  return (
    <section id='home' className='hero d-flex align-items-center'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6 d-flex flex-column justify-content-center'>
            <h1 data-aos='fade-up'>
              Your imagination is the limit - we will take care of the rest.
            </h1>
            <h2 data-aos='fade-up' data-aos-delay='400'>
              One stop IT solution for every project.
            </h2>
            <ContactBtn />
          </div>
          <div
            className='col-lg-6 hero-img'
            data-aos='zoom-out'
            data-aos-delay='200'
          >
            <img src={heroImg} className='img-fluid' alt='' />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
