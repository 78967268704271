import React, { useRef, useEffect } from "react";

import { navElements } from "../consts/common";
import LinkNavTop from "../components/links/LinkNavTop";
import Logo from "../components/Logo";
import useScroll from "../hooks/useScroll";
import { select, scrollto } from "../utils/common";

const Header = (props) => {
  const headerEl = useRef(null);
  const navbarEl = useRef(null);

  useScroll("header-scrolled", headerEl);

  useEffect(() => {
    if (window.location.hash) {
      if (select(window.location.hash)) {
        scrollto(window.location.hash);
      }
    }
  }, []);

  const mobileNavToggle = (e) => {
    navbarEl.current.classList.toggle("navbar-mobile");
    e.target.classList.toggle("bi-list");
    e.target.classList.toggle("bi-x");
  };

  return (
    <header ref={headerEl} id='header' className='header fixed-top'>
      <div className='container-fluid container-xl d-flex align-items-center justify-content-between'>
        <Logo />

        <nav ref={navbarEl} id='navbar' className='navbar'>
          <ul>
            {navElements.map((el, idx) => (
              <LinkNavTop key={idx} navEl={el} idx={idx} />
            ))}
          </ul>
          <i
            className='bi bi-list mobile-nav-toggle'
            onClick={mobileNavToggle}
          ></i>
        </nav>
      </div>
    </header>
  );
};

export default Header;
