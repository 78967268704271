import React from "react";

import MailtoBtn from "../components/MailtoBtn";

const Contact = (props) => {
  return (
    <section id='contact' className='contact'>
      <div className='container' data-aos='fade-up'>
        <header className='section-header'>
          <p>Contact Us</p>
        </header>

        <div className='row gy-4'>
          <div className='col-lg-10 mx-auto'>
            <h3 className='text-center'>
              Let us help you with your next project
            </h3>
            <MailtoBtn />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
