import React, { useRef } from "react";
import useScroll from "../hooks/useScroll";

const BackTopBtn = (props) => {
  const backTopBtnEl = useRef(null);

  useScroll("active", backTopBtnEl);

  return (
    // eslint-disable-next-line
    <a
      ref={backTopBtnEl}
      href='#'
      className='back-to-top d-flex align-items-center justify-content-center'
    >
      <i className='bi bi-arrow-up-short'></i>
    </a>
  );
};

export default BackTopBtn;
