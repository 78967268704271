import React from "react";

import logoImg from "../assets/img/logo.png";
import { companyName } from "../consts/common";

const Logo = (props) => {
  return (
    <a href='#home' className='logo d-flex align-items-center'>
      <img src={logoImg} alt='' />
      <span>{companyName}</span>
    </a>
  );
};

export default Logo;
