import { useEffect } from "react";

import useScrollPosition from "@react-hook/window-scroll";

const useScroll = (className, elRef) => {
  const scrollY = useScrollPosition(60 /*fps*/);

  useEffect(() => {
    if (scrollY > 100) {
      elRef.current.classList.add(className);
    } else {
      elRef.current.classList.remove(className);
    }
  }, [scrollY, className, elRef]);
};

export default useScroll;
