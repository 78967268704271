import React from "react";

import aboutImg from "../assets/img/about.jpg";

const Header = (props) => {
  return (
    <section id='about' className='about'>
      <div className='container' data-aos='fade-up'>
        <div className='row gx-0'>
          <div
            className='col-lg-6 d-flex flex-column justify-content-center'
            data-aos='fade-up'
            data-aos-delay='200'
          >
            <div className='content'>
              <h3>Who We Are</h3>
              <h2>We’re not just a Software House.</h2>
              <p>
                We are highly experienced individuals with high expectations for
                themselves.
              </p>
              <p>
                Think of us as a partner that is committed to implementing
                comprehensive IT solutions to meet your business goals and
                needs.
              </p>
            </div>
          </div>

          <div
            className='col-lg-6 d-flex align-items-center'
            data-aos='zoom-out'
            data-aos-delay='200'
          >
            <img src={aboutImg} className='img-fluid' alt='' />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
