import React, { useEffect, useRef } from "react";
import _ from "lodash";

const ServiceBox = (props) => {
  const boxEl = useRef(null);

  useEffect(() => {
    const color = _.shuffle([
      "blue",
      "orange",
      "green",
      "red",
      "purple",
      "pink",
    ])[0];
    boxEl.current.classList.add(color);
  }, []);

  return (
    <div className='col-lg-4 col-md-6' data-aos='fade-up' data-aos-delay='200'>
      <div ref={boxEl} className='service-box'>
        <h3>{props.text}</h3>
      </div>
    </div>
  );
};

export default ServiceBox;
