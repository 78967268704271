import React from "react";
import _ from "lodash";

import { select, scrollto } from "../../utils/common";

const LinkNavTop = (props) => {
  const { navEl } = props;

  const handleClick = (e) => {
    const target = e.target;
    if (select(target.hash)) {
      e.preventDefault();

      let navbar = select("#navbar");
      if (navbar.classList.contains("navbar-mobile")) {
        navbar.classList.remove("navbar-mobile");
        let navbarToggle = select(".mobile-nav-toggle");
        navbarToggle.classList.toggle("bi-list");
        navbarToggle.classList.toggle("bi-x");
      }
      scrollto(target.hash);
    }
  };

  return (
    <li>
      <a onClick={handleClick} className='nav-link scrollto' href={navEl.path}>
        {_.capitalize(navEl.name)}
      </a>
    </li>
  );
};

export default LinkNavTop;
