import React from "react";

import ServiceBox from "../components/ServiceBox";
import { serviceElements } from "../consts/common";

const Services = (props) => {
  return (
    <section id='services' className='services'>
      <div className='container' data-aos='fade-up'>
        <header className='section-header'>
          <p>What we do</p>
        </header>

        <div className='row gy-4'>
          {serviceElements.map((text, idx) => (
            <ServiceBox key={idx} text={text} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
