import React from "react";

const ContactBtn = (props) => {
  return (
    <div data-aos='fade-up' data-aos-delay='600'>
      <div className='text-center text-lg-start'>
        <a
          href='#contact'
          className='btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center'
        >
          <span>Contact us</span>
          <i className='bi bi-arrow-right'></i>
        </a>
      </div>
    </div>
  );
};

export default ContactBtn;
