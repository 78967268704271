import React from "react";
import PortfolioItem from "../components/PortfolioItem";

import { portfolioItems } from "../consts/common";

const Portfolio = (props) => {
  return (
    <section id='projects' class='portfolio'>
      <div class='container' data-aos='fade-up'>
        <header class='section-header'>
          <p>Our Projects</p>
        </header>

        <div
          class='row gy-4 portfolio-container'
          data-aos='fade-up'
          data-aos-delay='200'
        >
          {portfolioItems.map((el, idx) => (
            <PortfolioItem {...el} key={idx} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
