import React from "react";

import ValueBox from "../components/ValueBox";

import { valueElements } from "../consts/common";

const Values = (props) => {
  return (
    <section id='values' className='values'>
      <div className='container' data-aos='fade-up'>
        <header className='section-header'>
          <p>How we think</p>
        </header>

        <div className='row'>
          {valueElements.map((el, idx) => (
            <ValueBox key={idx} {...el} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Values;
