import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import "bootstrap-icons/font/bootstrap-icons.css";

import "aos/dist/aos.js";
import "aos/dist/aos.css";

import "./index.css";

import React, { useEffect } from "react";

import AOS from "aos";

import Header from "./sections/Header";
import Hero from "./sections/Hero";
import MainSection from "./sections/Main";
import Footer from "./sections/Footer";
import BackTopBtn from "./components/BackTopBtn";

const App = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
  }, []);

  return (
    <React.Fragment>
      <Header />
      <Hero />
      <MainSection />
      <Footer />
      <BackTopBtn />
    </React.Fragment>
  );
};

export default App;
